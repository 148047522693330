import styled from "@emotion/styled";
import Section from "../atoms/Section";
import SectionHeader from "../atoms/SectionHeader";
import Subsection from "../atoms/Subsection";
import Text from "../atoms/Text";
import earmap from "../assets/ear-map.png";

const ServicesSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 1025px) {
    #services-image {
      order: 2;
    }
    #services-content {
      order: 1;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  p {
    text-align: left;
    font-size: 1.5rem;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: unset;
    margin-right: unset;
  }
  width: 85%;
  justify-content: space-between;
`;

const ServicesDisclaimer = styled.span`
  font-family: "Avenir", sans-serif;
  font-weight: 500;
  color: #3a668c;
  padding: 0.1rem;
  font-size: 0.9rem;
  line-height: 1.1rem;
  margin-top: 0;
  width: 85%;
  text-align: left;

  @media (max-width: 1025px) {
    text-align: left;
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
`;

const ServicesImage = styled.img`
  @media (max-width: 1025px) {
    height: 100%;
    width: 100%;
  }
  width: 80%;
`;

const Services = () => {
  return (
    <ServicesSection id="services">
      <SectionHeader>PIERCING SERVICES</SectionHeader>
      <Text style={{ fontSize: "0.8rem", lineHeight: "0.8rem", marginTop: 0 }}>
        Effective Oct 1 2024
      </Text>
      <Section>
        <Subsection id="services-image">
          <ServicesImage src={earmap} alt="piercing-locations-image" />
        </Subsection>
        <Subsection id="services-content">
          <TextContainer>
            <Text>Lobe Piercing</Text>
            <Text>$50 per lobe</Text>
          </TextContainer>
          <TextContainer>
            <Text>Helix Piercing</Text>
            <Text>$50</Text>
          </TextContainer>
          <TextContainer>
            <Text>Tragus Piercing</Text>
            <Text>$60</Text>
          </TextContainer>
          <TextContainer>
            <Text>Conch Piercing</Text>
            <Text>$55</Text>
          </TextContainer>
          <TextContainer>
            <Text>Flat Piercing</Text>
            <Text>$55</Text>
          </TextContainer>
          <TextContainer>
            <Text>Forward Helix</Text>
            <Text>$60</Text>
          </TextContainer>
          <TextContainer>
            <Text>Daith Piercing</Text>
            <Text>$65</Text>
          </TextContainer>
          <TextContainer>
            <Text>Rook Piercing</Text>
            <Text>$65</Text>
          </TextContainer>
          <TextContainer>
            <Text>Nostril Piercing</Text>
            <Text>$65</Text>
          </TextContainer>
          <TextContainer>
            <Text>Jewelry Change Out</Text>
            <Text>$10</Text>
          </TextContainer>
          <ServicesDisclaimer>
            • All piercings include high quality flat back titanium jewelry
          </ServicesDisclaimer>
          <ServicesDisclaimer>
            • Additional jewelry options are available during time of
            appointment or for purchase separately
          </ServicesDisclaimer>
        </Subsection>
      </Section>
    </ServicesSection>
  );
};

export default Services;
