import styled from "@emotion/styled";
import Section from "../atoms/Section";
import Subsection from "../atoms/Subsection";
import Text from "../atoms/Text";
import instagramLogo from "../assets/instagram-logo.png";

const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  p {
    text-align: left;
  }

  #about-content {
    min-height: 100px;
    width: 100%;
    margin-top: 30px;
  }

  @media (max-width: 1025px) {
    #about-content {
      order: 1;
      min-height: 100px;
    }
  }
`;

const InstaLogo = styled.img`
  height: 3vmax;
`;

const InstaWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1025px) {
    p {
      font-size: 1.2rem;
    }
  }
`;

const InstaLink = styled.a`
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`;

const About = () => {
  return (
    <AboutSection id="about">
      <Section>
        <Subsection id="about-content">
          <InstaWrapper>
            <Text style={{ marginTop: "0.5rem" }}>Visit me on Instagram</Text>
            <InstaLink
              href="https://instagram.com/adornedbyemily"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstaLogo src={instagramLogo} alt="instagram-logo" />
            </InstaLink>
          </InstaWrapper>
        </Subsection>
      </Section>
    </AboutSection>
  );
};

export default About;
