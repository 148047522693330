import styled from '@emotion/styled';

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Avenir', sans-serif;
  color: #3a668c;
  font-size: 2rem;
  font-weight: 800;
  height: 5rem;
  margin-top: 20px;
`;

export default SectionHeader;
