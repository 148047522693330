import styled from '@emotion/styled';

const Link = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  transition: color 150ms ease-out;

  font-family: 'Avenir', sans-serif;
  font-size: 1.3rem;
  font-weight: 200;
  color: #ffffff;
  margin: 0 auto;

  &:hover {
    color: #3a668c;
  }

  @media (max-width: 1150px) {
    margin-right: 0.5 rem;
    margin-left: 0.5rem;
  }

  @media (max-width: 1025px) {
    font-size: 1.3rem;
    padding: 0.5rem;
    margin: unset;
    margin-left: 0.8rem;
  }
`;

export type NavLinkProps = {
  label: string;
  href: string;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};

const NavLink = ({ label, href, isOpen, setIsOpen }: NavLinkProps) => {
  return (
    <Link href={href} onClick={() => setIsOpen && setIsOpen(!isOpen)}>
      {label}
    </Link>
  );
};

export default NavLink;
