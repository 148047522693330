import styled from '@emotion/styled';

const Section = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 1025px) {
    flex-direction: column;
  }
  flex-direction: row;
`;

export default Section;
