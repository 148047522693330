import styled from '@emotion/styled';

const Text = styled.p`
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  color: #3a668c;
  padding: 0.1rem;
`;

export default Text;
