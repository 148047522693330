import styled from "@emotion/styled";
import Section from "../atoms/Section";
import SectionHeader from "../atoms/SectionHeader";
import Subsection from "../atoms/Subsection";
import Text from "../atoms/Text";
import earstack from "../assets/ear-stack.png";

const PoliciesSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 1025px) {
    #policies-image {
      order: 2;
    }
    #policies-content {
      order: 1;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const TextWrapper = styled.div`
  width: 50%;

  p {
    text-align: left;
  }

  @media (max-width: 1025px) {
    width: 85%;
    p {
      font-size: 1.2rem;
    }
  }
`;

const PoliciesImage = styled.img`
  @media (max-width: 1025px) {
    height: 100%;
    width: 100%;
  }
  width: 80%;
`;

//•

const Policies = () => {
  return (
    <PoliciesSection id="policies">
      <SectionHeader>POLICIES</SectionHeader>
      <Text style={{ fontSize: "0.8rem", lineHeight: "0.8rem", marginTop: 0 }}>
        Effective Oct 1 2024
      </Text>
      <Section>
        <Subsection id="policies-image">
          <PoliciesImage src={earstack} alt="earstack" />
        </Subsection>
        <Subsection id="policies-content">
          <TextWrapper style={{ marginBottom: "2rem", width: "80%" }}>
            <Text>
              Minors must come with parent/legal guardian. Parent/legal guardian
              must come with minor's birth certificate and a form of
              identification.
            </Text>
            <Text>I will not pierce anyone who is pregnant.</Text>
            <Text>
              You will NOT be charged the full service fee at the time of
              booking. If you no show or cancel within 48 hours, you will be
              charged the full service fee.
            </Text>
            <Text>All sales are final for services and jewelry purchased.</Text>
            <Text>
              Booking an appointment enters you into an agreement of the
              information and policies listed above.
            </Text>
          </TextWrapper>
        </Subsection>
      </Section>
    </PoliciesSection>
  );
};

export default Policies;
