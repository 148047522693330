import styled from "@emotion/styled";

const Subsection = styled.div`
  @media (max-width: 1025px) {
    width: 100%;
  }
  min-height: 400px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
`;

export default Subsection;
